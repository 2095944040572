import { query, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { GlobalOperationsQuery } from '@confluence/global-operations';
import { getIsProductAdminFromQuery, getIsSiteAdminFromQuery } from '@confluence/current-user';
import { ssrPrepareErrorsClient } from '@confluence/ssr-utilities';
import { OperationsQuery } from '@confluence/site-operations';
import { fg } from '@confluence/feature-gating';

import { DisplayConditionsSpaceAdminQuery } from './DisplayConditionsSpaceAdminQuery.graphql';
import type { UseStaticContextProps } from './useStaticContext';
import { UserPrivilegeQuery } from './UserPrivilegeQuery.graphql';

export async function preloadUseStaticContext({ spaceKey }: Partial<UseStaticContextProps>) {
	const { isLoggedIn } = await getPreloaderFnContext();

	try {
		const promises = [
			query({
				query: DisplayConditionsSpaceAdminQuery,
				variables: { spaceKey },
			}),
			query({ query: UserPrivilegeQuery }),
		];
		const isOperationsQueryMigrationEnabled = fg('confluence_operation_query_migration');
		if (!isOperationsQueryMigrationEnabled) {
			promises.push(query({ query: GlobalOperationsQuery }));
		}

		if (isOperationsQueryMigrationEnabled) {
			promises.push(query({ query: OperationsQuery }));
		}

		const [{ data: spaceAdminData }, { data: privilegeData }] = await Promise.all(promises);

		const isSpaceAdmin = (spaceAdminData as any)?.space?.currentUser?.isAdmin;
		const isSiteAdmin = getIsSiteAdminFromQuery(privilegeData as any);
		const isAdmin = isSiteAdmin || getIsProductAdminFromQuery(privilegeData as any);
		return {
			isSpaceAdmin,
			isLoggedIn,
			isAdmin,
			isSiteAdmin,
		};
	} catch (e) {
		ssrPrepareErrorsClient.submitError(
			new Error(`Cannot preload data for useStaticContext, using defaults for SSR: ${e.message}`),
		);

		return {
			isLoggedIn,
			isSpaceAdmin: false,
			isAdmin: false,
			isSiteAdmin: false,
		};
	}
}
