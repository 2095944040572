import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { Iframe, getModalDimensions } from '../../custom-ui';
import {
	type ModalIframeRendererProps,
	type CustomUIRendererProps,
	type ModalOptions,
} from './types';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { getAtlasKitModalWidth, ModalContentWrapper } from './custom-ui-modal-utils';

type Props = CustomUIRendererProps & {
	// These are internal props, not exposed to the product
	onModalCustomUIRendererClose?: () => void;
	isInModal?: boolean;
};

export const CustomUIRenderer = ({
	accountId,
	bridge,
	client,
	contextIds,
	coreData,
	customBridgeMethods,
	dialogs,
	entryPoint,
	extension,
	extensionData,
	extensionPayload,
	extensionViewData,
	getContextToken,
	getThreeLOPrompt,
	height,
	width,
	isIframeResizable,
	iframeRef,
	isInModal,
	loadingComponent,
	locale,
	localId,
	modalStyles,
	modalProvider,
	onIframeLoad,
	onModalCustomUIRendererClose,
	product,
	timezone,
	getModalWidth,
}: Props) => {
	// modalOptions is null when no modal is open is non null when opened
	const [modalOptions, setModalOptions] = useState<ModalOptions | null>(null);

	const onCloseFromApp = modalOptions?.onClose;

	const extensionInModal = useMemo(
		() => ({
			...extension,
			// Pass a type that is not included in the dimensions presets so that it falls back to default
			type: 'dynamic-modal',
			properties: {
				...extension.properties,
				resource: modalOptions?.resource ?? extension.properties.resource,
			},
		}),
		[extension, modalOptions?.resource],
	);

	const extensionDataInModal = useMemo(
		() => ({
			...extensionData,
			...(modalOptions?.context ? { modal: modalOptions?.context } : {}),
			// This is to handle an incompatibility issue with Compass and JSM where they are incorrectly currently spreading the context object on the top level instead of in the `modal` object.
			// Remove once Compass apps have been updated https://softwareteams.atlassian.net/browse/COMPASS-22051 and a notice to app developers have been sent out
			...((product === 'compass' || extension.type.startsWith('jiraServiceManagement')) &&
			modalOptions?.context
				? { ...modalOptions?.context }
				: {}),
		}),
		[extension.type, extensionData, modalOptions?.context, product],
	);

	const handleModalClose = useCallback(() => {
		setModalOptions(null);
	}, [setModalOptions]);

	// Confluence specific feature
	// Needed to show Forge modals in live pages. dialogs won't be passed in if the page isn't live.
	useEffect(() => {
		if (product !== 'confluence') {
			return;
		}
		if (dialogs && modalOptions) {
			dialogs.showDialog(ModalCustomUIRenderer, {
				extension: extensionInModal,
				extensionData: extensionDataInModal,
				localId,
				onTearDown: handleModalClose,
				bridge,
				size: modalOptions.size,
				closeOnEscape: modalOptions.closeOnEscape,
				closeOnOverlayClick: modalOptions.closeOnOverlayClick,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalOptions, dialogs]);

	const iframeProps = {
		accountId,
		apolloClient: client,
		contextIds,
		components: getThreeLOPrompt,
		coreData,
		customBridgeMethods,
		extension,
		extensionData,
		extensionPayload,
		extensionViewData,
		getContextToken,
		isResizable: isIframeResizable,
		locale,
		timezone,
		loadingComponent,
	};

	return (
		<React.Fragment>
			{/* isResizable defaults to true within Iframe */}
			<Iframe
				{...iframeProps}
				entryPoint={entryPoint}
				isResizable={isIframeResizable}
				bridge={{
					...bridge,
					openModal: (opts) => {
						setModalOptions(opts.data);
						return true;
					},
					...(onModalCustomUIRendererClose
						? {
								close: (payload: any) => {
									onModalCustomUIRendererClose?.();
									// This bridge.close reference is defined below in ModalCustomUIRenderer.
									// It's required to call the correct onCloseFromApp callback
									bridge?.close?.(payload);
									return true;
								},
							}
						: {}),
				}}
				height={height}
				width={width}
				isInModal={isInModal}
				ref={iframeRef}
				onLoad={onIframeLoad}
			/>
			{modalOptions ? (
				<ModalCustomUIRenderer
					{...iframeProps}
					product={product}
					getThreeLOPrompt={getThreeLOPrompt}
					dialogs={dialogs}
					client={client}
					extension={extensionInModal}
					extensionData={extensionDataInModal}
					localId={localId}
					onTearDown={handleModalClose}
					bridge={{
						...bridge,
						close: (payload: any) => {
							onCloseFromApp?.(payload.data);
							return true;
						},
					}}
					onClose={onCloseFromApp}
					size={modalOptions.size}
					closeOnEscape={modalOptions.closeOnEscape}
					closeOnOverlayClick={modalOptions.closeOnOverlayClick}
					modalStyles={modalStyles}
					modalProvider={modalProvider}
					getModalWidth={getModalWidth}
					isIframeResizable={isIframeResizable}
				/>
			) : null}
		</React.Fragment>
	);
};

export const ModalCustomUIRenderer = ({
	accountId,
	bridge,
	coreData,
	client,
	closeOnEscape,
	closeOnOverlayClick,
	consentMessage,
	contextIds,
	customBridgeMethods,
	dialogs,
	entryPoint,
	extension,
	extensionData,
	extensionPayload,
	extensionViewData,
	getContextToken,
	getThreeLOPrompt,
	height,
	isIframeResizable = false,
	loadingComponent,
	locale,
	localId,
	mentionProvider,
	modalExtension,
	modalStyles,
	modalProvider: ModalProvider,
	onClose,
	onConsentModalClose,
	onConsentSuccess,
	onError,
	onForgeDocUpdated,
	onIframeLoad,
	onTearDown,
	product,
	size,
	timezone,
	getModalWidth,
}: ModalIframeRendererProps) => {
	const [isOpen, setIsOpen] = useState(true);

	// This is for preventing inline dialog apps from being closed by an esc key press when a
	// modal is open until https://product-fabric.atlassian.net/browse/DSP-3552 is resolved.
	useEffect(() => {
		if (product !== 'confluence') {
			return;
		}
		function dismissEscape(e: any) {
			if (e.key === 'Escape') {
				e.stopPropagation();

				if (closeOnEscape) {
					onClose?.();
					setIsOpen(false);
				}
			}
		}

		if (isOpen) {
			document.addEventListener('keydown', dismissEscape, { capture: true });
		}

		return () =>
			document.removeEventListener('keydown', dismissEscape, {
				capture: true,
			});
	}, [isOpen, closeOnEscape, product, onClose]);

	const modalSize = size ?? extension.properties.viewportSize ?? 'medium';
	const modalWidth = getModalWidth ? getModalWidth(size) : getAtlasKitModalWidth(modalSize);
	const { height: modalHeight } = getModalDimensions(modalSize);

	const renderModal = () => (
		<Modal
			width={modalWidth}
			// "max" size modal have a height value of 100%, which needs to be set in this parent component
			// all other modal sizes have height values that do not depend on the parent component
			// therefore their heights should be handled by the `ModalContentsWrapper` below
			height={modalSize === 'max' ? modalHeight : undefined}
			onClose={() => {
				onClose?.();
				setIsOpen(false);
			}}
			onCloseComplete={onTearDown}
			testId="custom-ui-modal-dialog"
			shouldCloseOnOverlayClick={closeOnOverlayClick}
			shouldCloseOnEscapePress={closeOnEscape}
		>
			<ModalContentWrapper size={modalSize} styles={modalStyles} isResizable={isIframeResizable}>
				<CustomUIRenderer
					product={product}
					coreData={coreData}
					getThreeLOPrompt={getThreeLOPrompt}
					dialogs={dialogs}
					accountId={accountId}
					client={client}
					contextIds={contextIds}
					extension={extension}
					extensionData={extensionData}
					extensionViewData={extensionViewData}
					bridge={bridge}
					customBridgeMethods={customBridgeMethods}
					consentMessage={consentMessage}
					entryPoint={entryPoint}
					getContextToken={getContextToken}
					height={height}
					isIframeResizable={isIframeResizable}
					locale={locale}
					localId={localId}
					mentionProvider={mentionProvider}
					modalExtension={modalExtension}
					onConsentSuccess={onConsentSuccess}
					onForgeDocUpdated={onForgeDocUpdated}
					timezone={timezone}
					onConsentModalClose={onConsentModalClose}
					onError={onError}
					onIframeLoad={onIframeLoad}
					loadingComponent={loadingComponent}
					extensionPayload={extensionPayload}
					modalStyles={modalStyles}
					onModalCustomUIRendererClose={() => {
						onClose?.();
						setIsOpen(false);
					}}
					isInModal={true}
				/>
			</ModalContentWrapper>
		</Modal>
	);

	if (ModalProvider) {
		return (
			<ModalTransition>{isOpen && <ModalProvider>{renderModal()}</ModalProvider>}</ModalTransition>
		);
	}

	return <ModalTransition>{isOpen && renderModal()}</ModalTransition>;
};
