export const LIVE_PAGES_FEATURE_GATE = 'confluence_live_pages';
export const LIVE_PAGES_AB_TEST_OPTED_IN = 'confluence_live_pages_ab_test_opted_in';

// Note: this is controlled via Statsig
export const EXTENSION_POINTS_IN_LIVE_PAGES_GATE =
	'confluence_frontend_extension_points_in_live_pages';

// Feature gate on Statsig to control automation on action bar of live-edit page
export const AUTOMATION_ACTIONBAR_IN_LIVE_PAGES_GATE =
	'a4c-2824-add-automation-to-live-page-toolbar';
