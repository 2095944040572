import type { Fragment, Node as PMNode, Schema } from '@atlaskit/editor-prosemirror/model';
import {
	findParentNodeClosestToPos,
	findParentNodeOfType,
} from '@atlaskit/editor-prosemirror/utils';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import type { FeatureToggles } from '../feature-keys';
import type { ContentStatistics } from '../utils/prosemirror-content-statistics';
import { ProseMirrorContentStatistics } from '../utils/prosemirror-content-statistics';

import type { FallbackState, IDMap } from './pm-markdown/serializer';
import { MarkdownSerializerState } from './pm-markdown/serializer';
import type { MentionMap } from './types';

/**
 * Exporting the IDMap type so that future consumers of the transformer can be more type safe
 */
export type { IDMap } from './pm-markdown/serializer';

/**
 * If the first node is a paragraph we need to wrap it in a paragraph node for the markdown conversion to work properly
 */
function createTextWrapper(editorView: EditorView, fragment: PMNode | Fragment) {
	const { schema } = editorView.state;
	const { $from } = editorView.state.selection;
	const parent = findParentNodeClosestToPos($from, (node) => {
		return node.type.name === 'paragraph' || node.type.name === 'heading';
	})?.node;
	if (parent) {
		return schema.nodes.doc.createChecked(
			null,
			schema.nodes[parent.type.name].createChecked(parent.attrs, fragment),
		);
	} else {
		// If the node is a paragraph we need the parent to be a doc for the conversion to work correctly
		return schema.nodes.doc.createChecked(
			null,
			schema.nodes.paragraph.createChecked(null, fragment),
		);
	}
}

/**
 * This finds the closest list item parent to the current selection
 * Once found it will create a new list with the current fragment as its children
 */
function createListItemWrapper(editorView: EditorView, fragment: PMNode | Fragment) {
	const { schema } = editorView.state;
	const { $from } = editorView.state.selection;
	const parent = findParentNodeClosestToPos(
		$from,
		(node) => node.type.name === 'bulletList' || node.type.name === 'orderedList',
	)?.node;
	if (parent) {
		return schema.nodes[parent.type.name].createChecked(parent.attrs, fragment);
	}
	return fragment;
}

/**
 * If the first node is a task item we need to wrap it in a task list node for the markdown conversion to work properly
 */
function createTaskItemWrapper(schema: Schema, fragment: PMNode | Fragment) {
	return schema.nodes.doc.createChecked(null, schema.nodes.taskList.createChecked(null, fragment));
}

function createLayoutColumnWrapper(editorView: EditorView, fragment: PMNode | Fragment) {
	const { schema } = editorView.state;

	const parentSection = findParentNodeOfType(schema.nodes.layoutSection)(
		editorView.state.selection,
	);

	if (parentSection) {
		// Needs to be a doc for the serializer to work as it
		// loops through the fragments children to render
		return schema.nodes.doc.createChecked(
			null,
			schema.nodes.layoutSection.createChecked(parentSection.node.attrs, fragment),
		);
	}

	return fragment;
}

/**
 * This function handles partial selections and it will fix any incomplete fragments that won't be converted properly
 * If you do a partial selection, it will not select the parent node of certain nodes text, listItems etc so once it hits the serializer
 * the serializer will just fallback to plain text and not convert the node properly.
 *
 * This function checks the first child of the current selection, if its a node that needs a parent it will find the closest parent
 * and create a new fragment that will be converted properly by the serializer
 */
function checkFragment(editorView: EditorView, schema: Schema, fragment: PMNode | Fragment) {
	switch (fragment?.firstChild?.type?.name) {
		case 'text':
			return createTextWrapper(editorView, fragment);
		case 'listItem':
			return createListItemWrapper(editorView, fragment);
		case 'taskItem':
			return createTaskItemWrapper(schema, fragment);
		case 'layoutColumn':
			return createLayoutColumnWrapper(editorView, fragment);
		default:
			return fragment;
	}
}

/**
 * Converts a selection of nodes from a Prosemirror editor into Markdown.
 *
 * @param editorView - The EditorView instance of the current Prosemirror editor.
 * @param fragment - The ProseMirror node or fragment that is to be converted into Markdown.
 * @param featureToggles - Optional feature toggles to use for the conversion.
 * @param mentionMap - Optional mention map for serializing mentions.
 * @returns An object containing the converted markdown, the fallback state from the Markdown serializer, and the id map from the Markdown serializer.
 */
export function convertProsemirrorToMarkdown({
	editorView,
	fragment,
	featureToggles = {},
	mentionMap = {},
	idMap = {},
}: {
	editorView: EditorView;
	fragment: PMNode | Fragment;
	/**
	 * See {@link FeatureToggles} for available features
	 */
	featureToggles?: FeatureToggles;
	/**
	 * See {@link MentionMap} for more information
	 */
	mentionMap?: MentionMap;
	/**
	 * See {@link IDMap} for more information
	 */
	idMap?: IDMap;
}): {
	markdown: string;
	fallbackState: FallbackState;
	idMap: IDMap;
	contentStatistics: ContentStatistics;
} {
	fragment = checkFragment(editorView, editorView.state.schema, fragment);

	return convertProsemirrorNodeToMarkdown({
		node: fragment,
		featureToggles,
		mentionMap,
		idMap,
	});
}

/**
 * Converts a ProseMirror node or document node to markdown/ markdown plus.
 *
 * This function uses the `convertProsemirrorToMarkdown` implementation. Using a node instead of a
 * fragment means that we don't need access to the editorView or the schema to get the result.
 *
 * @param node - The ProseMirror node or document node that is to be converted into Markdown.
 * @param featureToggles - Optional feature toggles to use for the conversion.
 * @param mentionMap - Optional mention map for serializing mentions.
 * @returns An object containing the converted markdown, the fallback state from the Markdown serializer, and the id map from the Markdown serializer.
 */
export function convertProsemirrorNodeToMarkdown({
	node,
	featureToggles = {},
	mentionMap = {},
	idMap = {},
}: {
	node: PMNode | Fragment;
	/**
	 * See {@link FeatureToggles} for available features
	 */
	featureToggles?: FeatureToggles;
	/**
	 * See {@link MentionMap} for more information
	 */
	mentionMap?: MentionMap;
	idMap?: IDMap;
}): {
	markdown: string;
	fallbackState: FallbackState;
	idMap: IDMap;
	contentStatistics: ContentStatistics;
} {
	const markdownSerializerState = new MarkdownSerializerState(featureToggles, mentionMap, idMap);
	markdownSerializerState.renderContent(node);

	const pmStats = new ProseMirrorContentStatistics(node);
	const contentStatistics = pmStats.collectStatistics();

	const markdown =
		markdownSerializerState.out === '\u200c'
			? ''
			: // Return empty string if editor only contains a zero-non-width character
				markdownSerializerState.out;

	return {
		markdown,
		fallbackState: markdownSerializerState.fallbackState,
		idMap: markdownSerializerState.idMap,
		contentStatistics,
	};
}
