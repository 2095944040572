import FeatureGates from '@atlaskit/feature-gate-js-client';

/* eslint-disable check-credentials-option/has-credentials-option */
import { useMemo } from 'react';

import { cfetch } from '@confluence/network';

import { confluencePatterns } from './confluenceURLPatterns';
import { jiraPatterns } from './jiraURLPatterns';

// Exported for testing
export const RESOLVED = 'resolved';
export const FORBIDDEN = 'forbidden';
export const UNAUTHORIZED = 'unauthorized';
export const NOT_FOUND = 'not_found';

// Returns true on SSR and SPA if page was SSR'd
export const isPageSSRd = () => {
	return Boolean(process.env.REACT_SSR || window.__SSR_RENDERED__);
};

// Put initialState into a getter
export const useStoreOptions = (initialState, isPageSSRd) =>
	useMemo(() => {
		if (initialState && isPageSSRd) {
			return {
				storeOptions: {
					initialState,
				},
			};
		} else {
			return {};
		}
	}, [initialState, isPageSSRd]);

// Tests all confluence url patterns and returns true if the url matches
export const isValidConfluenceSmartlink = (node) => {
	return confluencePatterns.some((pattern) => pattern.test(node?.url));
};
// We are currently only SSRing inline Smart Links, all other types of Smart Links will be ignored
export const isInlineSmartLink = (node) => {
	return node?.['__typename'] === 'InlineSmartLink';
};

const isSpaceSidebarLink = (node) => {
	return node?.['__typename'] === 'SpaceSidebarLink';
};

// This function takes in a ContentSmartLinksQuery response and returns an array of SmartLink resourceUrls
export const getSmartLinks = (res) => {
	const content = res?.data?.contentSmartLinks?.nodes || [];

	let inputSmartLinks = content;

	if (!FeatureGates.checkGate('confluence_ssr_remove-smartlink-regex-filtering')) {
		inputSmartLinks = content.filter(isValidConfluenceSmartlink).filter(isInlineSmartLink);
	}

	return inputSmartLinks.map((node) => {
		return {
			appearance: 'inline',
			resourceUrl: node?.url,
		};
	});
};

// string begins with 1+ characters other than "/", has a "/", and is followed by one character other than "/"
// OR string begins with "/", is followed by 1 character other than "/", ends with any # of any character
// while ignoring capitalization
const relativeUrlMatch = new RegExp(`^[^\/]+\/[^\/].*$|^\/[^\/].*$`, 'i');

// This function takes in a SpaceNavigationQuery response and returns an array of SmartLink resourceUrls
export const getSmartShortcuts = (res) => {
	const content = res?.data?.spaceSidebarLinks?.quick;
	return content.filter(isSpaceSidebarLink).map((node) => {
		return {
			appearance: 'inline',
			// adding domain to relative confluence URLs so smart links don't error
			resourceUrl: relativeUrlMatch.test(node?.url)
				? window.location.origin + node?.url
				: node?.url,
		};
	});
};

// Creates a cookie for object-resolver header
export const createCookie = (userContext) => {
	const cookieTarget =
		process.env.NODE_ENV === 'production' ? 'cloud.session.token' : 'cloud.session.token.stg';

	return `${cookieTarget}=${userContext}`;
};

type ORSAccess = 'granted' | 'forbidden' | 'unauthorized';
// Map of status codes to string values
export const getStatus = (statusCode: number, access?: ORSAccess) => {
	const statusMap = {
		200: RESOLVED,
		401: UNAUTHORIZED,
		403: FORBIDDEN,
		404: NOT_FOUND,
	};

	if (access === FORBIDDEN || access === UNAUTHORIZED) {
		return access;
	}

	return statusMap[statusCode] || 'errored';
};

// Method to build initialState object with keys needed for SmartCardProvider from the SmartLinks endpoint response
export const getInitialState = (smartLinksResponse) => {
	// If we didn't get a response from SmartLinks endpoint, exit
	if (!Array.isArray(smartLinksResponse) || smartLinksResponse?.length < 1) {
		return {};
	}

	return smartLinksResponse?.reduce((map, metadata) => {
		const status = metadata?.status;

		const body = metadata?.body;
		const url = metadata?.body?.data?.url;
		const cardState = {
			status: getStatus(status, metadata?.body?.meta?.access),
			details: body,
		};
		return {
			...map,
			[url]: cardState,
		};
	}, {});
};

interface FetchORSType {
	userContext: string | undefined;
	url: string;
	body: string;
}

export const fetchORS = async ({ url, userContext, body }: FetchORSType): Promise<any> => {
	const fetchOptions = {
		method: 'POST',
		headers: {
			Cookie: createCookie(userContext),
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body,
	};

	return cfetch(url, fetchOptions);
};

export const isConfluenceURL = (obj) => {
	return confluencePatterns.some((pattern) => pattern.test(obj.resourceUrl));
};

export const isJiraURL = (obj) => {
	return jiraPatterns.some((pattern) => pattern.test(obj.resourceUrl));
};
