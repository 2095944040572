import React from 'react';

import { isFedRamp } from '@atlassian/atl-context';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { PageAnalyticsPageLoader } from '@confluence/confluence-analytics/entry-points/AnalyticsPagesLoaders';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { CONTEXT_PATH } from '@confluence/named-routes';
import type { RouteMatch } from '@confluence/route';
import { Redirection } from '@confluence/route-manager';

export const PageAnalyticsRoute = ({ params: { contentId }, search }: RouteMatch) => {
	//TODO - CCIR-11: Clean up this feature gate once the migration is complete
	const isAnalyticsUiMigrationEnabled = FeatureGates.checkGate(
		'confluence_frontend_analytics_ui_migration',
	);
	if (!isAnalyticsUiMigrationEnabled) {
		const isFedrampEnv = isFedRamp();

		const url = isFedrampEnv
			? `${CONTEXT_PATH}/plugins/servlet/ac/com.addonengine.analytics.moderate/analytics-content-page`
			: `${CONTEXT_PATH}/plugins/servlet/ac/com.addonengine.analytics/analytics-content-page`;
		return <Redirection href={`${url}${search}`} />;
	}

	return <PageAnalyticsPageLoader contentId={contentId} />;
};

PageAnalyticsRoute.NAVIGATION_PARAMS = ({
	params: { spaceKey, contentId, contentSlug },
}: RouteMatch) => ({
	Screen: {
		// screenEvent will be handled in the page components
		pageState: {
			spaceKey,
			contentId,
			contentSlug,
		},
	},
	MainLayout: {
		spaceKey,
		navView: CONTAINER_HOME_ID,
	},
});
