import gql from 'graphql-tag';
export const FeedPopularFeedQuery = gql`
	query FeedPopularFeedQuery(
		$first: Int
		$after: String
		$timeGranularity: String
		$includeAlias: Boolean = false
	) {
		popularFeed(first: $first, after: $after, timeGranularity: $timeGranularity)
			@connection(key: "homePopularFeed") {
			nodes {
				content {
					title
					id
					type
					subType
					emoji: properties(key: "emoji-title-published") {
						nodes {
							id
							value
						}
					}
					excerpt(length: 350)
					labels {
						nodes {
							name
						}
					}
					links {
						webui
					}
					space {
						alias @include(if: $includeAlias)
						id
						key
						name
					}
					likes {
						count
						currentUserLikes
					}
					history {
						createdDate
						createdBy {
							type
							displayName
							profilePicture {
								path
							}
							... on KnownUser {
								accountId
							}
							... on User {
								accountId
							}
							... on UnknownUser {
								accountId
							}
						}
						ownedBy {
							type
							displayName
							profilePicture {
								path
							}
							... on KnownUser {
								accountId
							}
							... on User {
								accountId
							}
						}
					}
					metadata {
						lastModifiedDate
						comments {
							commentsCount
						}
						currentuser {
							favourited {
								isFavourite
							}
						}
					}
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
`;
