import { expVal } from '@confluence/feature-experiments';

import { useCompanyHubPremiumGate } from './useCompanyHubPremiumGate';

export const useIsTryBeforeYouBuyState = ({ skip = false } = {}) => {
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate({
		skip,
	});

	return (
		shouldShowPremiumUpsell &&
		expVal('confluence_frontend_company_hub_tbyb', 'isTBYBEnabled', false)
	);
};
