import React from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import type { RouteMatch } from '@confluence/route';
import { SpaceRoleManagementScreen } from '@confluence/space-roles/entry-points/SpaceRoleManagementScreen';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { ConfluenceEdition, useSessionData } from '@confluence/session-data';
import { NotFound } from '@confluence/not-found';
import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { Redirection } from '@confluence/route-manager';

export const SpaceMembersRoute = ({ params: { spaceKey } }: RouteMatch) => {
	const { edition } = useSessionData();
	const isFreeEdition = edition === ConfluenceEdition.FREE;

	const isRBACFGEnabled = FeatureGates.checkGate('cc_perms_exp_rbac_fe_milestone_1');

	if (isFreeEdition) {
		return <NotFound />;
	} else if (!isRBACFGEnabled) {
		return <Redirection name={SPACE_OVERVIEW.name} params={{ spaceKey }} />;
	} else {
		return <SpaceRoleManagementScreen spaceKey={spaceKey} />;
	}
};

SpaceMembersRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey } }) => ({
	Screen: {
		screenEvent: { name: 'spaceMembersScreen', id: spaceKey },
		pageState: {
			routeName: name,
			spaceKey,
		},
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
	},
});
